:global {
  .steps {
    text-align: center;
    border-bottom: 1px dotted #c9ced3;
    box-sizing: border-box;
    height: 29px;
  }

  .step {
    display: inline-block;
    box-sizing: border-box;
    width: 145px;

    p {
      display: inline-block;
      padding-left: 3px;
      color: #a1aab7;
      font-size: 10px;
      line-height: 13px;
      letter-spacing: 0.05px;
      font-family: VisbyCF-bold, sans-serif;
    }

    .step-circle {
      vertical-align: middle;
      text-align: center;
      width: 16px;
      height: 16px;
      border-style: solid;
      border-width: 1px;
      border-color: #a1aab7;
      border-radius: 50px;
      display: inline-block;

      span {
        color: #a1aab7;
        padding-left: 1px;
        font-size: 13px;
        line-height: 9.7px;
        letter-spacing: 0.05px;
        font-family: VisbyCF-bold, sans-serif;
        position: relative;
        bottom: 1px;
        right: 0.5px;
      }
    }

    .step-circle.current {
      background-color: #0d8dc4;
      border-color: #0d8dc4;
      position: relative;

      &:before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #0d8dc4;
        position: absolute;
        left: 20px;
        top: 26px;
      }

      span {
        color: #fff;
      }

      ~ p {
        color: #0d8dc4;
      }
    }

    .step-circle.complete {
      background-color: #40b980;
      border-color: #40b980;
      position: relative;

      &:after {
        content: '';
        display: block;
        width: 3px;
        height: 8px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(40deg);
        position: absolute;
        left: 6px;
        top: 2px;
      }

      span {
        display: none;
      }

      ~ p {
        color: #40b980;
      }
    }
  }
}
